import cloneDeep from "lodash.clonedeep";
import { object, string } from "yup";

import { currentConfig } from "@vipbuques/config";
import { file } from "@vipbuques/fields";
import { CATEGORIES_QUERY } from "@vipbuques/graphql";

const MAX_SIZE = 1024 * 15;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const uploadPrefix = currentConfig.UPLOAD_PREFIX;

export const validationSchema = object().shape({
  name: string().required("Obrigatório"),
  description: string().required("Obrigatório"),
  image: file({ MAX_SIZE, SUPPORTED_FORMATS }),
});

export const initialValues: any = {
  name: "",
  description: "",
  image: "",
};

export const options = {
  refetchQueries: [
    {
      query: CATEGORIES_QUERY,
    },
  ],
};

export const deserializeCategories = (data) => {
  const values = cloneDeep(data);

  if (values?.image?.formats?.thumbnail.url) {
    if (!values.image.formats.thumbnail.url.startsWith(uploadPrefix)) {
      values.image.formats.thumbnail.url = `${uploadPrefix}${values.image.formats.thumbnail.url}`;
    }
  }

  if (values?.image?.url) {
    if (!values.image.url.startsWith(uploadPrefix)) {
      values.image.url = `${uploadPrefix}${values.image.url}`;
    }
  }

  return values;
};

export const serializeCategories = (data) => {
  const { __typename, id, ...values } = data;

  if (values.image) {
    values.image = values.image.id;
  }

  return values;
};
