import React from "react";
import { Switch, Route } from "react-router-dom";

import { PagesAdd } from "./PagesAdd";
import { PagesList } from "./PagesList";
import { PagesView } from "./PagesView";

export enum PAGES_ROUTES {
  list = `/paginas`,
  edit = `/paginas/:id`,
  add = `/paginas/novo`,
}

export default () => (
  <Switch>
    <Route exact path={PAGES_ROUTES.list} component={PagesList} />
    <Route exact path={PAGES_ROUTES.add} component={PagesAdd} />
    <Route exact path={PAGES_ROUTES.edit} component={PagesView} />
  </Switch>
);
