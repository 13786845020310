import cloneDeep from "lodash.clonedeep";
import { object, string, boolean } from "yup";

import { PAGES_QUERY } from "@vipbuques/graphql";

export const validationSchema = object().shape({
  title: string().required("Obrigatório"),
  content: string().required("Obrigatório"),
  header: boolean().nullable(),
  footer: boolean().nullable(),
});

export const initialValues: any = {
  title: "",
  content: "",
  header: false,
  footer: false,
};

export const options = {
  refetchQueries: [
    {
      query: PAGES_QUERY,
    },
  ],
};

export const deserializePages = (data) => {
  const values = cloneDeep(data);

  return values;
};

export const serializePages = (data) => {
  const { __typename, id, ...values } = data;

  return values;
};
