import React from "react";

import { CREATE_PAGES_MUTATION } from "@vipbuques/graphql";

import { PagesForm } from "./PagesForm";
import { PAGES_ROUTES } from "./PagesRoutes";
import { validationSchema, options, initialValues } from "./PagesUtils";
import { BaseAdd } from "app/baseView";

export const PagesAdd = () => {
  const title = "Nova Página";
  const createMutation = CREATE_PAGES_MUTATION;
  const routes = PAGES_ROUTES;
  const createInput = "createPage";
  const createItemInput = "createPageInput";
  const schema = validationSchema;
  const ItemForm = PagesForm;
  const itemName = "page";

  return (
    <BaseAdd
      title={title}
      createMutation={createMutation}
      routes={routes}
      createInput={createInput}
      createItemInput={createItemInput}
      validationSchema={schema}
      ItemForm={ItemForm}
      mutationOptions={options}
      initialValues={initialValues}
      itemName={itemName}
    />
  );
};

export default PagesAdd;
