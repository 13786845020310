"use strict";

exports.__esModule = true;
exports.DELETE_PRODUCTS_MUTATION = exports.UPDATE_PRODUCTS_MUTATION = exports.CREATE_PRODUCTS_MUTATION = exports.PRODUCT_DETAIL_QUERY = exports.PRODUCT_QUERY = exports.PRODUCTS_QUERY = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject6() {
  const data = _taggedTemplateLiteral(["\n  mutation deleteProduct($deleteProductInput: deleteProductInput) {\n    deleteProduct(input: $deleteProductInput) {\n      product {\n        id\n      }\n    }\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n  mutation updateProduct($updateProductInput: updateProductInput) {\n    updateProduct(input: $updateProductInput) {\n      product {\n        id\n      }\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  mutation createProduct($createProductInput: createProductInput) {\n    createProduct(input: $createProductInput) {\n      product {\n        id\n      }\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  query product($id: ID!) {\n    product(id: $id) {\n      ", "\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  query products($where: JSON!) {\n    products(where: $where) {\n      ", "\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  {\n    products(sort: \"id:asc\") {\n      ", "\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

const product = "\n      id\n      active\n      title\n      slug\n      description\n      shortDescription\n      price\n      stock\n      categories {\n        id\n        name\n      }\n      photos {\n        id\n        url\n        mime\n        name\n        size\n        formats\n      }\n      mainPhoto {\n        id\n        url\n        mime\n        name\n        size\n        formats\n      }\n    }\n";
const PRODUCTS_QUERY = (0, _graphqlTag.default)(_templateObject(), product);
exports.PRODUCTS_QUERY = PRODUCTS_QUERY;
const PRODUCT_QUERY = (0, _graphqlTag.default)(_templateObject2(), product);
exports.PRODUCT_QUERY = PRODUCT_QUERY;
const PRODUCT_DETAIL_QUERY = (0, _graphqlTag.default)(_templateObject3(), product);
exports.PRODUCT_DETAIL_QUERY = PRODUCT_DETAIL_QUERY;
const CREATE_PRODUCTS_MUTATION = (0, _graphqlTag.default)(_templateObject4());
exports.CREATE_PRODUCTS_MUTATION = CREATE_PRODUCTS_MUTATION;
const UPDATE_PRODUCTS_MUTATION = (0, _graphqlTag.default)(_templateObject5());
exports.UPDATE_PRODUCTS_MUTATION = UPDATE_PRODUCTS_MUTATION;
const DELETE_PRODUCTS_MUTATION = (0, _graphqlTag.default)(_templateObject6());
exports.DELETE_PRODUCTS_MUTATION = DELETE_PRODUCTS_MUTATION;