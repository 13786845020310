"use strict";

exports.__esModule = true;
exports.DASHBOARD_QUERY = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  {\n    pages {\n      id\n    }\n    categories {\n      id\n    }\n    products {\n      id\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

const DASHBOARD_QUERY = (0, _graphqlTag.default)(_templateObject());
exports.DASHBOARD_QUERY = DASHBOARD_QUERY;