import React from "react";
import { Box } from "theme-ui";

export const Card = ({ sx = {}, children }) => (
  <Box
    sx={{
      bg: "white",
      p: 4,
      mb: 3,
      boxShadow:
        "inset 0 0 1px rgba(67,90,111,.47), 0 2px 4px -2px rgba(67,90,111,.3)",
      ...sx,
    }}>
    {children}
  </Box>
);
