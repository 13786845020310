import { useFormikContext, FieldArray } from "formik";
import React from "react";
import { Styled, Text, Divider, Button, Flex, Box } from "theme-ui";

import { PictureUploadField } from "@vipbuques/fields";
import { Icon } from "@vipbuques/icons";
import { Absolute } from "@vipbuques/layout";

export const PicturesUploadField = ({ token }) => {
  const { values } = useFormikContext<any>();

  return (
    <Box>
      <Divider />
      <Styled.h3>Galeria de imagens</Styled.h3>

      <FieldArray
        name="photos"
        render={(arrayHelpers) => (
          <fieldset
            style={{ border: "none", padding: 0, position: "relative" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              {values.photos &&
                values.photos.length > 0 &&
                values.photos.map((photo, index) => {
                  return (
                    <Box key={index} mr={3} sx={{ mr: 3 }}>
                      <PictureUploadField
                        label={`Imagem ${index + 1}`}
                        name={`photos.${index}`}
                        placeholder="Carregue uma imagem"
                        token={token}
                        onRemove={() => {
                          arrayHelpers.remove(index);
                        }}
                        required
                      />
                      <Divider />
                    </Box>
                  );
                })}
            </Flex>

            <Flex sx={{ justifyContent: "flex-start", mb: 3 }}>
              <Button type="button" onClick={() => arrayHelpers.push("")}>
                <Flex sx={{ alignItems: "center" }}>
                  <Icon name="Add" size="12" />
                  <Text ml="2">Adicionar imagem</Text>
                </Flex>
              </Button>
            </Flex>
          </fieldset>
        )}
      />

      <Text>
        <small>
          <b>Tamanho máximo: </b> 5MB
        </small>
      </Text>
      <Text>
        <small>
          <b>Formatos suportados: </b> jpg, jpeg ou png
        </small>
      </Text>
    </Box>
  );
};
