import React from "react";
import { Flex } from "theme-ui";

export const Footer = () => {
  return (
    <Flex
      sx={{
        bg: "black",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        mx: "auto",
      }}>
      <Flex
        sx={{
          alignItems: "stretch",
          justifyContent: "flex-start",
          mx: "auto",
          div: {
            px: 2,
          },
        }}>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "center",
            flex: "1 0 auto",
            flexDirection: ["column", null, "row"],
            pt: [2, null, 0],
          }}></Flex>
      </Flex>
    </Flex>
  );
};
