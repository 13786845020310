import { Formik, Form } from "formik";
import React, { useRef } from "react";
import { Redirect } from "react-router";
import { Flex, Styled, Box, Button, Label } from "theme-ui";
import { object, string } from "yup";

import { SpinnerAbsolute } from "@vipbuques/admin-layout";
import { TextField } from "@vipbuques/fields";

import { useAuthentication } from "app/authentication/hooks";

const initialValues = {
  identifier: "",
  password: "",
};

const validationSchema = object().shape({
  identifier: string().required("Obrigatório"),
  password: string().required("Obrigatório"),
});

export const LoginView = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const redirect = urlParams.get("redirect");
  const { login, loading, isAuthenticated, error } = useAuthentication(token);
  const form = useRef(null);

  if (isAuthenticated) {
    return <Redirect to={redirect || "/"} />;
  }

  return (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}>
      <Box
        sx={{
          width: ["100%", "400px"],
          bg: "white",
          p: 4,
          borderRadius: 2,
          position: "relative",
        }}>
        <Formik
          validateOnMount={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            login(form);
          }}>
          {({ isValid }) => {
            return (
              <Form autoComplete="off" noValidate={true} ref={form}>
                <Flex sx={{ flexDirection: "column" }}>
                  <Box sx={{ mb: 3 }}>
                    <Styled.h3>Autenticação</Styled.h3>
                  </Box>
                  <Box>
                    <TextField name="identifier" label="Username" />
                  </Box>
                  <Box>
                    <TextField
                      name="password"
                      label="Password"
                      type="password"
                    />
                  </Box>
                  <Flex sx={{ justifyContent: "flex-end" }}>
                    <Button disabled={!isValid} type="submit">
                      Entrar
                    </Button>
                  </Flex>
                </Flex>
                {loading && <SpinnerAbsolute />}
                {error && (
                  <>
                    <Styled.hr />
                    <Label
                      variant="label.error"
                      sx={{ mt: 2, textAlign: "center", fontSize: 2 }}>
                      {error}
                    </Label>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Flex>
  );
};
