import React from "react";

import { CREATE_CATEGORIES_MUTATION } from "@vipbuques/graphql";

import { CategoriesForm } from "./CategoriesForm";
import { CATEGORIES_ROUTES } from "./CategoriesRoutes";
import { validationSchema, options, initialValues } from "./CategoriesUtils";
import { BaseAdd } from "app/baseView";

export const CategoriesAdd = () => {
  const title = "Nova Categoria";
  const createMutation = CREATE_CATEGORIES_MUTATION;
  const routes = CATEGORIES_ROUTES;
  const createInput = "createCategory";
  const createItemInput = "createCategoryInput";
  const schema = validationSchema;
  const ItemForm = CategoriesForm;
  const itemName = "category";

  return (
    <BaseAdd
      title={title}
      createMutation={createMutation}
      routes={routes}
      createInput={createInput}
      createItemInput={createItemInput}
      validationSchema={schema}
      ItemForm={ItemForm}
      mutationOptions={options}
      initialValues={initialValues}
      itemName={itemName}
    />
  );
};

export default CategoriesAdd;
