import Logo from "assets/logo.jpg";
import React from "react";
import { Flex, Image, Text } from "theme-ui";

import { Logout } from "app/authentication/Logout";

export const Navbar = () => {
  return (
    <Flex
      sx={{
        bg: "primary",
        color: "white",
        height: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        px: 2,
        boxShadow: "0px 0px 15px -5px black",
        width: "100%",
      }}>
      <Flex
        sx={{
          alignItems: "center",
        }}>
        <Image
          src={Logo}
          sx={{ width: 40, mr: 3, bg: "white", p: 1, borderRadius: 4 }}
        />
        <Text sx={{ fontSize: 3 }}>VipBuques</Text>
      </Flex>
      <Logout />
    </Flex>
  );
};
