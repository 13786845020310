import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { Flex, Text, Box, Badge } from "theme-ui";

import { GridLayout, SpinnerAbsolute } from "@vipbuques/admin-layout";
import { DASHBOARD_QUERY } from "@vipbuques/graphql";

const Block = ({ sx = {}, ...props }) => (
  <Flex
    sx={{
      bg: "white",
      p: 4,
      m: 0,
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      mb: 3,
      boxShadow:
        "inset 0 0 1px rgba(67,90,111,.47), 0 2px 4px -2px rgba(67,90,111,.3)",
      print: {
        bg: "white",
        p: 0,
        mb: 1,
        boxShadow: "none",
      },
      ...sx,
    }}
    {...props}
  />
);

const Title = ({ sx = {}, ...props }) => (
  <Text
    variant="styles.h2"
    sx={{ mt: 0, mb: 3, textAlign: "center", ...sx }}
    {...props}
  />
);

const Number = ({ sx = {}, ...props }) => (
  <Badge
    variant="rounded"
    sx={{
      m: 0,
      px: 2,
      fontSize: 5,
      bg: "success",
      color: "white",
      width: "auto",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ml: "4",
      ...sx,
    }}
    {...props}
  />
);

export const Dashboard = () => {
  const { loading, data } = useQuery<any>(DASHBOARD_QUERY);

  return (
    <Box sx={{ height: "100%" }}>
      {loading && <SpinnerAbsolute />}
      {data && (
        <GridLayout>
          <Block>
            <Title>Páginas</Title>
            <Flex
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Number sx={{ bg: "info" }}>{data.pages.length}</Number>
            </Flex>
          </Block>
          <Block>
            <Title>Categorias</Title>
            <Flex
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Number sx={{ bg: "info" }}>{data.categories.length}</Number>
            </Flex>
          </Block>
          <Block>
            <Title>Produtos</Title>
            <Flex
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Number sx={{ bg: "info" }}>{data.products.length}</Number>
            </Flex>
          </Block>
        </GridLayout>
      )}
    </Box>
  );
};

export default Dashboard;
