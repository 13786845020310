import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Redirect } from "react-router-dom";
import { Box, Text, Button, Label, Flex } from "theme-ui";

import { SpinnerAbsolute } from "@vipbuques/admin-layout";
import { Icon } from "@vipbuques/icons";

import { Card } from "app/layout/Card";
import { useRedirect, useAlertSuccess } from "app/utils";

export const BaseView = ({
  id,
  title,
  query,
  routes,
  updateMutation,
  deleteMutation = {},
  queryItem,
  serialize,
  deserialize,
  updateInput,
  validationSchema,
  deleteInput,
  ItemDelete,
  ItemForm,
  mutationOptions,
  Actions = null,
}: any) => {
  const setRedirect = useRedirect();
  const [state, setState] = useState<any>({});
  const { data, loading, refetch } = useQuery(query, {
    variables: { where: { id } },
  });

  const [upadateData, { ...update }] = useMutation(
    updateMutation,
    mutationOptions
  );
  const isDisabled = loading || update.loading;
  const alertSuccess = useAlertSuccess();

  useEffect(() => {
    if (data?.[queryItem][0]?.id) {
      const values = deserialize(data[queryItem][0]);
      setState(values);
    }
  }, [data]);

  const updateHandler = (data) => {
    const values = serialize(data);
    upadateData({
      variables: {
        [updateInput]: { where: { id }, data: values },
      },
    })
      .then(() => {
        refetch({ where: { id } });
        alertSuccess("Item autalizado com sucesso.");
        setRedirect(routes.list);
      })
      .catch((e) => {
        Sentry.withScope(function (scope) {
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(e);
        });
      });
  };

  if (!data?.[queryItem][0]?.id && !loading) {
    return <Redirect to={routes.list} />;
  }

  return (
    <>
      {loading && <SpinnerAbsolute />}
      {state?.id && (
        <Card
          sx={{
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            zIndex: 2,
            mb: 6,
          }}>
          <Text variant="styles.h2" sx={{ mb: 4 }}>
            {title}
          </Text>

          <Formik
            validateOnMount={false}
            initialValues={state}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              updateHandler(values);
            }}>
            {({ isValid, errors }) => {
              return (
                <Form autoComplete="off" noValidate={true}>
                  <fieldset
                    disabled={isDisabled}
                    style={{ border: "none", padding: 0 }}>
                    <ItemForm isDisabled={isDisabled} />
                    <Box
                      sx={{
                        position: "fixed",
                        bottom: "50px",
                        width: "100%",
                        right: "24px",
                      }}>
                      <Card
                        sx={{
                          display: "flex",
                          mb: 0,
                          bg: "white",
                          width: "100%",
                          p: 3,
                          justifyContent: "flex-end",
                        }}>
                        {update.loading && <SpinnerAbsolute />}
                        <Box>
                          {update.error && (
                            <Label
                              variant="label.error"
                              sx={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              Erro ao guardar, tente novamente...
                            </Label>
                          )}
                        </Box>
                        <Flex
                          sx={{
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "flex-end",
                          }}>
                          {Actions && (
                            <Actions
                              id={state.id}
                              refetch={refetch}
                              state={state}
                            />
                          )}
                          <Button
                            type="button"
                            variant="outline"
                            sx={{ mr: 3 }}
                            onClick={() => setRedirect(routes.list)}>
                            <Flex sx={{ alignItems: "center" }}>
                              <Icon name="ArrowBack" size={20} />
                              <Text sx={{ mr: 2 }}>Voltar</Text>
                            </Flex>
                          </Button>
                          {deleteMutation && (
                            <ItemDelete
                              sx={{ mr: 3 }}
                              disabled={isDisabled}
                              mutation={deleteMutation}
                              variables={{
                                [deleteInput]: {
                                  where: { id },
                                },
                              }}
                              onDelete={() => setRedirect(routes.list)}>
                              Remover
                            </ItemDelete>
                          )}
                          <Button
                            type="submit"
                            variant="primary"
                            disabled={!isValid || isDisabled}>
                            Guardar
                          </Button>
                        </Flex>
                      </Card>
                    </Box>
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </Card>
      )}
    </>
  );
};
