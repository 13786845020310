import React from "react";

import { CREATE_PRODUCTS_MUTATION } from "@vipbuques/graphql";

import { ProductsForm } from "./ProductsForm";
import { PRODUCTS_ROUTES } from "./ProductsRoutes";
import { validationSchema, options, initialValues } from "./ProductsUtils";
import { BaseAdd } from "app/baseView";

export const ProductsAdd = () => {
  const title = "Novo Produto";
  const createMutation = CREATE_PRODUCTS_MUTATION;
  const routes = PRODUCTS_ROUTES;
  const createInput = "createProduct";
  const createItemInput = "createProductInput";
  const schema = validationSchema;
  const ItemForm = ProductsForm;
  const itemName = "product";

  return (
    <BaseAdd
      title={title}
      createMutation={createMutation}
      routes={routes}
      createInput={createInput}
      createItemInput={createItemInput}
      validationSchema={schema}
      ItemForm={ItemForm}
      mutationOptions={options}
      initialValues={initialValues}
      itemName={itemName}
    />
  );
};

export default ProductsAdd;
