"use strict";

exports.__esModule = true;
exports.currentConfig = exports.config = void 0;
const config = {
  development: {
    API: "http://localhost:1338",
    PUBLIC_URL: "/",
    UPLOAD_PREFIX: "http://localhost:1338"
  },
  test: {},
  production: {
    API: "https://api.vipbuques.com",
    PUBLIC_URL: "/",
    UPLOAD_PREFIX: "https://api.vipbuques.com"
  }
};
exports.config = config;

const currentConfig = (() => {
  if (process.env.NODE_ENV) {
    return config[process.env.NODE_ENV];
  }

  return null;
})();

exports.currentConfig = currentConfig;