import React from "react";

import {
  DELETE_PAGES_MUTATION,
  UPDATE_PAGES_MUTATION,
} from "@vipbuques/graphql";
import { PAGE_QUERY } from "@vipbuques/graphql";

import { PagesDelete } from "./PagesDelete";
import { PagesForm } from "./PagesForm";
import { PAGES_ROUTES } from "./PagesRoutes";
import {
  validationSchema,
  deserializePages,
  serializePages,
} from "./PagesUtils";
import { options } from "./PagesUtils";
import { BaseView } from "app/baseView";

export const PagesView = (props) => {
  const title = "Editar Página";
  const id = props.match.params.id;
  const query = PAGE_QUERY;
  const updateMutation = UPDATE_PAGES_MUTATION;
  const deleteMutation = DELETE_PAGES_MUTATION;
  const routes = PAGES_ROUTES;
  const queryItem = "pages";
  const deserialize = deserializePages;
  const serialize = serializePages;
  const updateInput = "updatePageInput";
  const deleteInput = "deletePageInput";
  const schema = validationSchema;
  const ItemDelete = PagesDelete;
  const ItemForm = PagesForm;
  const mutationOptions = options;

  return (
    <BaseView
      id={id}
      title={title}
      query={query}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      routes={routes}
      queryItem={queryItem}
      deserialize={deserialize}
      serialize={serialize}
      updateInput={updateInput}
      deleteInput={deleteInput}
      validationSchema={schema}
      ItemDelete={ItemDelete}
      ItemForm={ItemForm}
      mutationOptions={mutationOptions}
    />
  );
};

export default PagesView;
