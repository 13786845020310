import cloneDeep from "lodash.clonedeep";
import { object, string, boolean, number, array } from "yup";

import { currentConfig } from "@vipbuques/config";
import { file, fileNotRequired } from "@vipbuques/fields";
import { PRODUCTS_QUERY } from "@vipbuques/graphql";

const MAX_SIZE = 1024 * 15;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const uploadPrefix = currentConfig.UPLOAD_PREFIX;

export const validationSchema = object().shape({
  title: string().required("Obrigatório"),
  active: boolean(),
  price: number().required("Obrigatório"),
  stock: number().required("Obrigatório"),
  description: string().required("Obrigatório"),
  shortDescription: string().required("Obrigatório"),
  mainPhoto: file({ MAX_SIZE, SUPPORTED_FORMATS }),
  photos: array().of(fileNotRequired({ MAX_SIZE, SUPPORTED_FORMATS })),
  categories: array().of(
    object().shape({
      id: number(),
      name: string(),
    })
  ),
});

export const initialValues: any = {
  title: "",
  active: false,
  price: 0,
  stock: 0,
  description: "",
  shortDescription: "",
  mainPhoto: "",
  photos: [],
  categories: [],
};

export const options = {
  refetchQueries: [
    {
      query: PRODUCTS_QUERY,
    },
  ],
};

export const deserializeProducts = (data) => {
  const values = cloneDeep(data);

  if (values?.mainPhoto?.formats?.thumbnail.url) {
    if (!values.mainPhoto.formats.thumbnail.url.startsWith(uploadPrefix)) {
      values.mainPhoto.formats.thumbnail.url = `${uploadPrefix}${values.mainPhoto.formats.thumbnail.url}`;
    }
  }

  if (values?.mainPhoto?.url) {
    if (!values.mainPhoto.url.startsWith(uploadPrefix)) {
      values.mainPhoto.url = `${uploadPrefix}${values.mainPhoto.url}`;
    }
  }

  values.categories = values.categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  values.photos = values.photos.map((photo) => {
    if (photo?.formats?.thumbnail.url) {
      if (!photo.formats.thumbnail.url.startsWith(uploadPrefix)) {
        photo.formats.thumbnail.url = `${uploadPrefix}${photo.formats.thumbnail.url}`;
      }
    }
    return {
      ...photo,
      url: photo.url ? `${uploadPrefix}${photo.url}` : null,
    };
  });

  return values;
};

export const serializeProducts = (data) => {
  const { __typename, id, photos, categories, ...values } = data;

  if (values.mainPhoto) {
    values.mainPhoto = values.mainPhoto.id;
  }

  values.categories = categories.map((category) => category.value);
  values.photos = photos.map((photo) => photo.id);

  return values;
};
