"use strict";

exports.__esModule = true;
exports.PRODUCTS_FROM_CATEGORY_QUERY = exports.HOME_QUERY = exports.DASHBOARD_QUERY = exports.DELETE_PRODUCTS_MUTATION = exports.UPDATE_PRODUCTS_MUTATION = exports.CREATE_PRODUCTS_MUTATION = exports.PRODUCT_DETAIL_QUERY = exports.PRODUCT_QUERY = exports.PRODUCTS_QUERY = exports.DELETE_CATEGORIES_MUTATION = exports.UPDATE_CATEGORIES_MUTATION = exports.CREATE_CATEGORIES_MUTATION = exports.CATEGORY_QUERY = exports.CATEGORIES_QUERY = exports.DELETE_PAGES_MUTATION = exports.UPDATE_PAGES_MUTATION = exports.CREATE_PAGES_MUTATION = exports.PAGE_QUERY = exports.PAGES_QUERY = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _pages = require("./pages");

exports.PAGES_QUERY = _pages.PAGES_QUERY;
exports.PAGE_QUERY = _pages.PAGE_QUERY;
exports.CREATE_PAGES_MUTATION = _pages.CREATE_PAGES_MUTATION;
exports.UPDATE_PAGES_MUTATION = _pages.UPDATE_PAGES_MUTATION;
exports.DELETE_PAGES_MUTATION = _pages.DELETE_PAGES_MUTATION;

var _categories = require("./categories");

exports.CATEGORIES_QUERY = _categories.CATEGORIES_QUERY;
exports.CATEGORY_QUERY = _categories.CATEGORY_QUERY;
exports.CREATE_CATEGORIES_MUTATION = _categories.CREATE_CATEGORIES_MUTATION;
exports.UPDATE_CATEGORIES_MUTATION = _categories.UPDATE_CATEGORIES_MUTATION;
exports.DELETE_CATEGORIES_MUTATION = _categories.DELETE_CATEGORIES_MUTATION;

var _products = require("./products");

exports.PRODUCTS_QUERY = _products.PRODUCTS_QUERY;
exports.PRODUCT_QUERY = _products.PRODUCT_QUERY;
exports.PRODUCT_DETAIL_QUERY = _products.PRODUCT_DETAIL_QUERY;
exports.CREATE_PRODUCTS_MUTATION = _products.CREATE_PRODUCTS_MUTATION;
exports.UPDATE_PRODUCTS_MUTATION = _products.UPDATE_PRODUCTS_MUTATION;
exports.DELETE_PRODUCTS_MUTATION = _products.DELETE_PRODUCTS_MUTATION;

var _dashboard = require("./dashboard");

exports.DASHBOARD_QUERY = _dashboard.DASHBOARD_QUERY;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  query categories($slug: String!) {\n    categories(where: { slug: $slug }) {\n      slug\n      name\n      description\n      products {\n        id\n        slug\n        title\n        description\n        shortDescription\n        stock\n        active\n        photos {\n          id\n          url\n          formats\n        }\n        mainPhoto {\n          id\n          url\n          formats\n        }\n        price\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  {\n    home {\n      header {\n        url\n        formats\n      }\n      block {\n        title\n        description\n        image {\n          url\n          formats\n        }\n      }\n      products {\n        id\n        slug\n        title\n        description\n        shortDescription\n        stock\n        active\n        photos {\n          id\n          url\n          formats\n        }\n        mainPhoto {\n          id\n          url\n          formats\n        }\n        price\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

const HOME_QUERY = (0, _graphqlTag.default)(_templateObject());
exports.HOME_QUERY = HOME_QUERY;
const PRODUCTS_FROM_CATEGORY_QUERY = (0, _graphqlTag.default)(_templateObject2());
exports.PRODUCTS_FROM_CATEGORY_QUERY = PRODUCTS_FROM_CATEGORY_QUERY;