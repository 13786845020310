import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useMemo } from "react";

import { currentConfig } from "@vipbuques/config";

import { AUTHENTICATION_ROUTES } from "app/authentication/AuthenticationRoutes";

let apolloClient;

const httpLink = createHttpLink({
  uri: `${currentConfig.API}/api`,
  credentials: "same-origin",
});

const authLink = new ApolloLink((operation, forward) => {
  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("auth")
      ? JSON.parse(window.localStorage.getItem("auth"))
      : null;
  }

  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const [error] = graphQLErrors;
    const statusCode = error?.extensions?.exception?.output?.statusCode;
    if (statusCode === 401) {
      window.location.href = AUTHENTICATION_ROUTES.logout;
    }
  }
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.concat(errorLink, ApolloLink.concat(authLink, httpLink)),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();
  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
