import React, { useEffect } from "react";
import { Redirect } from "react-router";

import { useAuthentication } from "app/authentication/hooks";
import { useApollo } from "app/utils/useApollo";

export const LogoutView = () => {
  const { logout, isAuthenticated } = useAuthentication();
  const client = useApollo();

  useEffect(() => {
    client.resetStore();
    logout();
  }, [client, logout]);

  if (!isAuthenticated) {
    return <Redirect to={"/"} />;
  }

  return null;
};
