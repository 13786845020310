import React from "react";

import {
  DELETE_PRODUCTS_MUTATION,
  UPDATE_PRODUCTS_MUTATION,
} from "@vipbuques/graphql";
import { PRODUCT_QUERY } from "@vipbuques/graphql";

import { ProductsDelete } from "./ProductsDelete";
import { ProductsForm } from "./ProductsForm";
import { PRODUCTS_ROUTES } from "./ProductsRoutes";
import {
  validationSchema,
  deserializeProducts,
  serializeProducts,
} from "./ProductsUtils";
import { options } from "./ProductsUtils";
import { BaseView } from "app/baseView";

export const ProductsView = (props) => {
  const title = "Editar Produto";
  const id = props.match.params.id;
  const query = PRODUCT_QUERY;
  const updateMutation = UPDATE_PRODUCTS_MUTATION;
  const deleteMutation = DELETE_PRODUCTS_MUTATION;
  const routes = PRODUCTS_ROUTES;
  const queryItem = "products";
  const deserialize = deserializeProducts;
  const serialize = serializeProducts;
  const updateInput = "updateProductInput";
  const deleteInput = "deleteProductInput";
  const schema = validationSchema;
  const ItemDelete = ProductsDelete;
  const ItemForm = ProductsForm;
  const mutationOptions = options;

  return (
    <BaseView
      id={id}
      title={title}
      query={query}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      routes={routes}
      queryItem={queryItem}
      deserialize={deserialize}
      serialize={serialize}
      updateInput={updateInput}
      deleteInput={deleteInput}
      validationSchema={schema}
      ItemDelete={ItemDelete}
      ItemForm={ItemForm}
      mutationOptions={mutationOptions}
    />
  );
};

export default ProductsView;
