"use strict";

exports.__esModule = true;
exports.default = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _themeGet = _interopRequireDefault(require("@styled-system/theme-get"));

var _Area = _interopRequireDefault(require("./Area"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  height: 100vh;\n\n  grid-template-columns: 1fr;\n\n  grid-template-areas:\n    \"navbar navbar\"\n    \"sidebar content\"\n    \"sidebar footer\";\n\n  grid-template-columns: ", " 1fr;\n  grid-template-rows: ", " 1fr ", ";\n\n  ", " {\n    grid-template-columns: ", " 1fr;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

const MainLayout = (0, _styled.default)(_Area.default)(_templateObject(), (0, _themeGet.default)("sizes.sidebarCollapsed"), (0, _themeGet.default)("sizes.navbar"), (0, _themeGet.default)("sizes.footer"), ({
  theme
}) => "@media screen and (min-width: ".concat(theme.breakpoints[2], ")"), ({
  theme,
  sidebarCollapsed
}) => sidebarCollapsed ? (0, _themeGet.default)("sizes.sidebarCollapsed")({
  theme
}) : (0, _themeGet.default)("sizes.sidebar")({
  theme
}));
var _default = MainLayout;
exports.default = _default;