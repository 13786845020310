import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import { useField } from "formik";
import React from "react";
import { Label, Box } from "theme-ui";

import { Block } from "@vipbuques/admin-layout";

import { UploadAdapter } from "./uploader";
import { useToken } from "app/authentication/hooks";

export const RichTextField = ({ label, ...props }) => {
  const token = useToken();
  const [field, meta, helpers] = useField(props.name);
  const hasError = meta.touched && meta.error;

  return (
    <Block>
      <Label
        htmlFor={props.id || props.name}
        variant={hasError ? "label.error" : "label"}>
        {label} {props.required ? "*" : ""}
      </Label>
      <Box
        sx={{
          borderWidth: "1px",
          borderColor: "gray.7",
          borderStyle: "solid",
          borderRadius: 2,
        }}>
        <CKEditor
          config={{
            toolbar: {
              items: [
                "undo",
                "redo",
                "bold",
                "italic",
                "blockQuote",
                // "ckfinder",
                "imageTextAlternative",
                "imageUpload",
                "heading",
                "imageStyle:full",
                "imageStyle:side",
                "link",
                "numberedList",
                "bulletedList",
                "mediaEmbed",
                "insertTable",
                "tableColumn",
                "tableRow",
                "mergeTableCells",
              ],
              shouldNotGroupWhenFull: true,
            },
          }}
          editor={ClassicEditor}
          data={field.value}
          onChange={(event, editor) => {
            const data = editor.getData();
            helpers.setValue(data);
          }}
          onInit={(editor) => {
            editor.plugins.get(
              "FileRepository"
            ).createUploadAdapter = function (loader) {
              return new UploadAdapter(loader, token);
            };
          }}
        />
      </Box>
      {hasError ? <Label variant="label.validation">{meta.error}</Label> : null}
    </Block>
  );
};
