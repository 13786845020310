import React from "react";
import { Box } from "theme-ui";

import { PictureUploadField, TextField } from "@vipbuques/fields";

import { useToken } from "app/authentication/hooks";
import { RichTextField } from "app/fields";

export const FILE_SUPPORTED_FORMATS = ["application/pdf"];
export const FILE_MAX_SIZE = 1024 * 15;

export const CategoriesForm = ({ isDisabled = false }) => {
  const token = useToken();
  return (
    <Box>
      <TextField label="Nome" name="name" required />
      <PictureUploadField
        label="Imagem"
        name="image"
        placeholder="Carregue uma imagem"
        token={token}
        required
      />
      <RichTextField label="Descrição" name="description" />
    </Box>
  );
};
