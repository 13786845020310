"use strict";

exports.__esModule = true;
exports.DELETE_PAGES_MUTATION = exports.UPDATE_PAGES_MUTATION = exports.CREATE_PAGES_MUTATION = exports.PAGE_QUERY = exports.PAGES_QUERY = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n  mutation deletePage($deletePageInput: deletePageInput) {\n    deletePage(input: $deletePageInput) {\n      page {\n        id\n      }\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  mutation updatePage($updatePageInput: updatePageInput) {\n    updatePage(input: $updatePageInput) {\n      page {\n        id\n      }\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  mutation createPage($createPageInput: createPageInput) {\n    createPage(input: $createPageInput) {\n      page {\n        id\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  query pages($where: JSON!) {\n    pages(where: $where) {\n      id\n      title\n      slug\n      content\n      footer\n      header\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  {\n    pages(sort: \"id:asc\") {\n      id\n      title\n      slug\n      content\n      footer\n      header\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

const PAGES_QUERY = (0, _graphqlTag.default)(_templateObject());
exports.PAGES_QUERY = PAGES_QUERY;
const PAGE_QUERY = (0, _graphqlTag.default)(_templateObject2());
exports.PAGE_QUERY = PAGE_QUERY;
const CREATE_PAGES_MUTATION = (0, _graphqlTag.default)(_templateObject3());
exports.CREATE_PAGES_MUTATION = CREATE_PAGES_MUTATION;
const UPDATE_PAGES_MUTATION = (0, _graphqlTag.default)(_templateObject4());
exports.UPDATE_PAGES_MUTATION = UPDATE_PAGES_MUTATION;
const DELETE_PAGES_MUTATION = (0, _graphqlTag.default)(_templateObject5());
exports.DELETE_PAGES_MUTATION = DELETE_PAGES_MUTATION;