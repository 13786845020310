import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Button, Box } from "theme-ui";

import { SpinnerAbsolute, Table } from "@vipbuques/admin-layout";
import { Icon } from "@vipbuques/icons";

import { useRedirect } from "app/utils";

export const BaseList = ({ query, columns, routes, title, queryItem }) => {
  const history = useHistory();
  const setRedirect = useRedirect();
  const { data, loading } = useQuery(query);

  return (
    <>
      <Flex
        sx={{
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 2,
          mb: 3,
          bg: "white",
          px: 3,
          py: 2,
          flexDirection: ["column", null, "row"],
        }}>
        <Text variant="styles.h2" sx={{ mx: 0, mt: 0, mb: [2, null, 0] }}>
          {title}
        </Text>
        <Button onClick={() => setRedirect(routes.add)} variant="success">
          <Flex sx={{ alignItems: "center" }}>
            <Icon name="Add" size={15} />
            <Text ml={2}>Adicionar</Text>
          </Flex>
        </Button>
      </Flex>
      {loading && <SpinnerAbsolute />}
      <Box sx={{ overflow: "scroll" }}>
        {data?.[queryItem] && (
          <Table.TableUi
            columns={columns}
            data={data[queryItem]}
            clickedRow={true}
            onClick={(row) => history.push(`${routes.list}/${row.id}`)}
          />
        )}
      </Box>
    </>
  );
};

export default BaseList;
