import React from "react";
import { Route, Switch } from "react-router-dom";

import { LoginView } from "./LoginView";
import { LogoutView } from "./LogoutView";

export enum AUTHENTICATION_ROUTES {
  base = `/authentication`,
  view = `/authentication/login`,
  logout = `/authentication/logout`,
}

export default () => (
  <Switch>
    <Route
      exact={true}
      path={AUTHENTICATION_ROUTES.view}
      component={LoginView}
    />
    <Route
      exact={true}
      path={AUTHENTICATION_ROUTES.logout}
      component={LogoutView}
    />
  </Switch>
);
