"use strict";

exports.__esModule = true;
exports.colors = void 0;
const gray = ["#F5F5F5", //0
"#DCDCDC", //1
"#D3D3D3", //2
"#D8D8D8", //3
"#C0C0C0", //4
"#BEBEBE", //5
"#A9A9A9", //6
"#808080" //7
];
const colors = {
  text: "#37352F",
  background: "#fff",
  primary: "#116AB8",
  secondary: "black",
  brand: "#f2c53a",
  muted: "#f6f6f6",
  warning: "#D9822B",
  danger: "#D64540",
  white: "#fff",
  black: "#1B1B1B",
  whiteAlpha: [],
  disabled: "#ccc",
  success: "#399D6C",
  gray,
  modes: {
    dark: {
      text: "#fff",
      background: "#000",
      primary: "#07c",
      secondary: "#30c",
      muted: "#f6f6f6",
      warning: "orange",
      danger: "#D64540"
    }
  }
};
exports.colors = colors;
colors.gray[700] = colors.gray[7];
colors.whiteAlpha[900] = "white";