"use strict";

exports.__esModule = true;
exports.DELETE_CATEGORIES_MUTATION = exports.UPDATE_CATEGORIES_MUTATION = exports.CREATE_CATEGORIES_MUTATION = exports.CATEGORY_QUERY = exports.CATEGORIES_QUERY = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n  mutation deleteCategory($deleteCategoryInput: deleteCategoryInput) {\n    deleteCategory(input: $deleteCategoryInput) {\n      category {\n        id\n      }\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  mutation updateCategory($updateCategoryInput: updateCategoryInput) {\n    updateCategory(input: $updateCategoryInput) {\n      category {\n        id\n      }\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  mutation createCategory($createCategoryInput: createCategoryInput) {\n    createCategory(input: $createCategoryInput) {\n      category {\n        id\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  query categories($where: JSON!) {\n    categories(where: $where) {\n      id\n      name\n      slug\n      description\n      image {\n        id\n        url\n        mime\n        name\n        size\n        formats\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  {\n    categories(sort: \"id:asc\") {\n      id\n      name\n      slug\n      description\n      image {\n        id\n        url\n        mime\n        name\n        size\n        formats\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

const CATEGORIES_QUERY = (0, _graphqlTag.default)(_templateObject());
exports.CATEGORIES_QUERY = CATEGORIES_QUERY;
const CATEGORY_QUERY = (0, _graphqlTag.default)(_templateObject2());
exports.CATEGORY_QUERY = CATEGORY_QUERY;
const CREATE_CATEGORIES_MUTATION = (0, _graphqlTag.default)(_templateObject3());
exports.CREATE_CATEGORIES_MUTATION = CREATE_CATEGORIES_MUTATION;
const UPDATE_CATEGORIES_MUTATION = (0, _graphqlTag.default)(_templateObject4());
exports.UPDATE_CATEGORIES_MUTATION = UPDATE_CATEGORIES_MUTATION;
const DELETE_CATEGORIES_MUTATION = (0, _graphqlTag.default)(_templateObject5());
exports.DELETE_CATEGORIES_MUTATION = DELETE_CATEGORIES_MUTATION;