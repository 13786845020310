import React from "react";

import { PRODUCTS_QUERY } from "@vipbuques/graphql";

import { PRODUCTS_ROUTES } from "./ProductsRoutes";
import { BaseList } from "app/baseView";

export const ProductsList = () => {
  const title = "Produtos";
  const query = PRODUCTS_QUERY;
  const routes = PRODUCTS_ROUTES;
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "products",
        columns: [
          {
            Header: "Nome",
            Cell: ({ row }) => row.original.title,
          },
          {
            Header: "URL",
            Cell: ({ row }) => `/${row.original.slug}`,
          },
        ],
      },
    ],
    []
  );

  return (
    <BaseList
      query={query}
      columns={columns}
      routes={routes}
      title={title}
      queryItem="products"
    />
  );
};

export default ProductsList;
