import React from "react";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";

import { AUTHENTICATION_ROUTES } from "./AuthenticationRoutes";
import { useLocalStorage } from "./hooks";

const ProtectedComponent = ({ children }) => {
  const [isAuthenticated] = useLocalStorage("auth", null);

  if (isAuthenticated) {
    return children;
  }

  return <Redirect to={AUTHENTICATION_ROUTES.view} />;
};

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <ProtectedComponent>
          <Component {...props} />
        </ProtectedComponent>
      )}
    />
  );
};
