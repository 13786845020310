import React from "react";
import { Switch, Route } from "react-router-dom";

import { CategoriesAdd } from "./CategoriesAdd";
import { CategoriesList } from "./CategoriesList";
import { CategoriesView } from "./CategoriesView";

export enum CATEGORIES_ROUTES {
  list = `/categorias`,
  edit = `/categorias/:id`,
  add = `/categorias/novo`,
}

export default () => (
  <Switch>
    <Route exact path={CATEGORIES_ROUTES.list} component={CategoriesList} />
    <Route exact path={CATEGORIES_ROUTES.add} component={CategoriesAdd} />
    <Route exact path={CATEGORIES_ROUTES.edit} component={CategoriesView} />
  </Switch>
);
