import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider } from "@chakra-ui/core";
import { theme as initialTheme } from "@chakra-ui/core";
import React, { Suspense, useState } from "react";
import { isMobile } from "react-device-detect";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import {
  SpinnerAbsolute,
  MainLayout,
  NavbarArea,
  FooterArea,
  ContentArea,
  SidebarArea,
  EmptyLayout,
} from "@vipbuques/admin-layout";
import { themeAdmin as defaultTheme } from "@vipbuques/themes";
import { GlobalStyles } from "@vipbuques/themes";

import { AuthenticationRoutes, ProtectedRoute } from "./authentication";
import { AUTHENTICATION_ROUTES } from "./authentication/AuthenticationRoutes";
import { CategoriesRoutes } from "./categories";
import { DashboardRoutes } from "./dashboard";
import { PagesRoutes } from "./pages";
import { ProductsRoutes } from "./products";
import { useApollo } from "./utils/useApollo";
import { Navbar, Footer, Sidebar } from "app/layout";

const ScrollTo = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return null;
};

export const LoginContainer = () => {
  return (
    <EmptyLayout>
      <NavbarArea>
        <Navbar />
      </NavbarArea>
      <ContentArea>
        <Route component={AuthenticationRoutes} />
      </ContentArea>
      <FooterArea>
        <Footer />
      </FooterArea>
    </EmptyLayout>
  );
};

export const AppContainer = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(isMobile);

  return (
    <Suspense fallback={<SpinnerAbsolute />}>
      <MainLayout sidebarCollapsed={sidebarCollapsed}>
        <NavbarArea>
          <Navbar />
        </NavbarArea>
        <SidebarArea>
          <Sidebar
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={() => setSidebarCollapsed(!sidebarCollapsed)}
          />
        </SidebarArea>
        <ContentArea>
          <DashboardRoutes />
          <PagesRoutes />
          <CategoriesRoutes />
          <ProductsRoutes />
        </ContentArea>
        <FooterArea>
          <Footer />
        </FooterArea>
      </MainLayout>
    </Suspense>
  );
};

export const theme: any = { ...initialTheme, ...defaultTheme };
export const App = () => {
  const client = useApollo();
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyles theme={theme} />
        <BrowserRouter>
          <Route component={ScrollTo} />
          <Switch>
            <Route
              path={AUTHENTICATION_ROUTES.base}
              component={LoginContainer}
            />
            <ProtectedRoute component={AppContainer} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
};
