import React from "react";
import { Switch, Route } from "react-router-dom";

import { ProductsAdd } from "./ProductsAdd";
import { ProductsList } from "./ProductsList";
import { ProductsView } from "./ProductsView";

export enum PRODUCTS_ROUTES {
  list = `/produtos`,
  edit = `/produtos/:id`,
  add = `/produtos/novo`,
}

export default () => (
  <Switch>
    <Route exact path={PRODUCTS_ROUTES.list} component={ProductsList} />
    <Route exact path={PRODUCTS_ROUTES.add} component={ProductsAdd} />
    <Route exact path={PRODUCTS_ROUTES.edit} component={ProductsView} />
  </Switch>
);
