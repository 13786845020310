import React from "react";
import { Flex, Text } from "theme-ui";

import { Link } from "@vipbuques/admin-layout";
import { Icon } from "@vipbuques/icons";

export enum Roles {
  Secretaria = "Secretaria",
  Midia = "Midia",
  Admin = "Admin",
}

const SidebarItem = ({ sidebarCollapsed, onClick, to, icon, label }: any) => {
  return (
    <Link to={to} onClick={() => (!sidebarCollapsed ? onClick() : null)}>
      <Flex sx={{ alignItems: "center", pb: 3 }}>
        <Icon name={icon} size={25} />
        {!sidebarCollapsed && <Text pl={2}>{label}</Text>}
      </Flex>
    </Link>
  );
};

export const Sidebar = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        position: ["fixed", null, "relative"],
        top: ["50px", null, 0],
        bg: "white",
        height: "100%",
        zIndex: "sidebar",
        boxShadow: "0px 0px 15px -5px black",
      }}>
      <Flex
        onClick={setSidebarCollapsed}
        sx={{
          justifyContent: "flex-end",
          px: 3,
          pt: 3,
          cursor: "pointer",
        }}>
        <Icon name="Menu" size={25} />
      </Flex>
      <Flex
        sx={{
          px: 3,
          pt: 3,
          pb: 4,
          flexDirection: "column",
          alignItems: "flex-start",
        }}>
        <SidebarItem
          to="/"
          icon="Dashboard"
          label="Dashboard"
          sidebarCollapsed={sidebarCollapsed}
          onClick={setSidebarCollapsed}
        />

        <SidebarItem
          to="/paginas"
          icon="New"
          label="Páginas"
          sidebarCollapsed={sidebarCollapsed}
          onClick={setSidebarCollapsed}
        />

        <SidebarItem
          to="/categorias"
          icon="Categories"
          label="Categorias"
          sidebarCollapsed={sidebarCollapsed}
          onClick={setSidebarCollapsed}
        />

        <SidebarItem
          to="/produtos"
          icon="Product"
          label="Produtos"
          sidebarCollapsed={sidebarCollapsed}
          onClick={setSidebarCollapsed}
        />

        <SidebarItem
          to="/encomendas"
          icon="Order"
          label="Encomendas"
          sidebarCollapsed={sidebarCollapsed}
          onClick={setSidebarCollapsed}
        />

        <SidebarItem
          to="/clientes"
          icon="Users"
          label="Clientes"
          sidebarCollapsed={sidebarCollapsed}
          onClick={setSidebarCollapsed}
        />

        <SidebarItem
          to="/configuracoes"
          icon="Settings"
          label="Configurações"
          sidebarCollapsed={sidebarCollapsed}
          onClick={setSidebarCollapsed}
        />
      </Flex>
    </Flex>
  );
};
