import React from "react";
import { Switch, Route } from "react-router-dom";

import { Dashboard } from "./Dashboard";

export enum DASHBOARD_ROUTES {
  view = `/`,
}

export default () => (
  <Switch>
    <Route exact path={DASHBOARD_ROUTES.view} component={Dashboard} />
  </Switch>
);
