"use strict";

exports.__esModule = true;
exports.default = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Area = _interopRequireDefault(require("./Area"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  background-color: transparent;\n  height: 100%;\n  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));\n  grid-template-rows: repeat(2, 200px);\n  grid-gap: 20px;\n\n  ", " {\n    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

const GridLayout = (0, _styled.default)(_Area.default)(_templateObject(), ({
  theme
}) => "@media screen and (min-width: ".concat(theme.breakpoints[2], ")"));
var _default = GridLayout;
exports.default = _default;