import React from "react";

import { PAGES_QUERY } from "@vipbuques/graphql";

import { PAGES_ROUTES } from "./PagesRoutes";
import { BaseList } from "app/baseView";

export const PagesList = () => {
  const title = "Páginas";
  const query = PAGES_QUERY;
  const routes = PAGES_ROUTES;
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "pages",
        columns: [
          {
            Header: "Título",
            Cell: ({ row }) => row.original.title,
          },
          {
            Header: "URL",
            Cell: ({ row }) => `/${row.original.slug}`,
          },
        ],
      },
    ],
    []
  );

  return (
    <BaseList
      query={query}
      columns={columns}
      routes={routes}
      title={title}
      queryItem="pages"
    />
  );
};

export default PagesList;
