import React from "react";
import { Redirect } from "react-router";
import { Box, Flex } from "theme-ui";

import { Icon } from "@vipbuques/icons";

import { AUTHENTICATION_ROUTES } from "./AuthenticationRoutes";
import { useAuthentication } from "./hooks";
import { useApollo } from "app/utils/useApollo";

export const Logout = () => {
  const { logout, isAuthenticated } = useAuthentication();
  const client = useApollo();

  if (!isAuthenticated) {
    client.resetStore();
    return <Redirect to={AUTHENTICATION_ROUTES.view} />;
  }

  return (
    <Flex
      sx={{
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={logout}>
      <Box mr={2}>Sair</Box> <Icon name="Logout" />
    </Flex>
  );
};
