import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { Box, Flex, Grid } from "theme-ui";

import { SpinnerAbsolute } from "@vipbuques/admin-layout";
import {
  PictureUploadField,
  SwitchField,
  TextField,
  SelectMultipleField,
} from "@vipbuques/fields";
import { CATEGORIES_QUERY } from "@vipbuques/graphql";

import { useToken } from "app/authentication/hooks";
import { RichTextField } from "app/fields";
import { PicturesUploadField } from "app/fields/PicturesUploadField";

export const FILE_SUPPORTED_FORMATS = ["application/pdf"];
export const FILE_MAX_SIZE = 1024 * 15;

export const ProductsForm = ({ isDisabled = false }) => {
  const token = useToken();
  const { data, loading } = useQuery(CATEGORIES_QUERY);

  if (loading) return <SpinnerAbsolute />;
  const { categories } = data;

  const categoryOptions = categories.map((c) => ({
    value: c.id,
    label: c.name,
  }));

  return (
    <Box>
      <Flex sx={{ mb: 4, justifyContent: "flex-start" }}>
        <SwitchField disabled={isDisabled} name="active" label="Ativo" />
      </Flex>
      <TextField label="Nome" name="title" required />
      <SelectMultipleField
        placeholder=""
        label="Categorias"
        name="categories"
        options={categoryOptions}
        isClearable={true}
      />
      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, null, "1fr 1fr"],
          rowGap: 2,
          columnGap: 4,
          mb: 2,
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <TextField label="Preço" name="price" type="number" required />
        </Box>
        <Box sx={{ gridColumn: [1, null, null, 2] }}>
          <TextField label="Estoque" name="stock" type="number" required />
        </Box>
      </Grid>
      <TextField label="Resumo" name="shortDescription" required />
      <PictureUploadField
        label="Imagem principal"
        name="mainPhoto"
        placeholder="Carregue uma imagem"
        token={token}
        required
      />
      <RichTextField label="Conteúdo" name="description" />
      <PicturesUploadField token={token} />
    </Box>
  );
};
