"use strict";

exports.__esModule = true;
exports.fileNotRequired = exports.file = exports.date = void 0;

var _dateFns = require("date-fns");

var _yup = require("yup");

const date = () => (0, _yup.mixed)().required("Obrigatório").test("invalidDate", "Data inválida", value => {
  try {
    const date = (0, _dateFns.parse)(value, "dd/MM/yyyy", new Date());
    (0, _dateFns.format)(date, "dd/MM/yyyy");

    if (date > new Date() || date < new Date("01-01-1900")) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
});

exports.date = date;

const file = ({
  MAX_SIZE,
  SUPPORTED_FORMATS
}) => (0, _yup.mixed)().required("Obrigatório").test("fileFormat", "Formato não suportado", value => value && SUPPORTED_FORMATS.includes(value.mime)).test("fileSize", "Ficheiro muito grande", value => value && value.size <= MAX_SIZE);

exports.file = file;

const fileNotRequired = ({
  MAX_SIZE,
  SUPPORTED_FORMATS
}) => (0, _yup.mixed)().test("fileFormat", "Formato não suportado", value => {
  return value && value.mime ? SUPPORTED_FORMATS.includes(value.mime) : false;
}).test("fileSize", "Ficheiro muito grande", value => {
  return value && value.size ? value.size <= MAX_SIZE : false;
});

exports.fileNotRequired = fileNotRequired;