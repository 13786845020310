import { useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Formik, Form } from "formik";
import React, { useEffect } from "react";
import { Box, Flex, Text, Button, Label } from "theme-ui";

import { SpinnerAbsolute } from "@vipbuques/admin-layout";
import { Icon } from "@vipbuques/icons";

import { Card } from "app/layout/Card";
import { useRedirect, useAlertSuccess } from "app/utils";

export const BaseAdd = ({
  createMutation,
  mutationOptions,
  createInput,
  createItemInput,
  routes,
  validationSchema,
  title,
  ItemForm,
  initialValues,
  itemName,
  serialize = null,
}: any) => {
  const setRedirect = useRedirect();
  const [createData, { loading, data, error }] = useMutation(
    createMutation,
    mutationOptions
  );
  const alertSuccess = useAlertSuccess();

  const createHandler = (data) => {
    let values = data;
    if (serialize) {
      values = serialize(data);
    }

    createData({
      variables: { [createItemInput]: { data: values } },
    }).catch((e) => {
      Sentry.withScope(function (scope) {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    });
  };

  useEffect(() => {
    if (data?.[createInput][itemName].id) {
      setRedirect(routes.list);
      alertSuccess("Item adicionada com sucesso");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Card
      sx={{
        justifyContent: "space-between",
        position: "sticky",
        top: 0,
        zIndex: 2,
      }}>
      <Text variant="styles.h2" sx={{ mb: 3 }}>
        {title}
      </Text>

      <Formik
        validateOnMount={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          createHandler(values);
        }}>
        {({ isValid }) => {
          return (
            <Form autoComplete="off" noValidate={true}>
              <fieldset
                disabled={loading}
                style={{ border: "none", padding: 0 }}>
                <ItemForm />
                <Box
                  sx={{
                    position: "fixed",
                    bottom: "50px",
                    left: ["26px", null, "276px"],
                    right: "26px",
                  }}>
                  {loading && <SpinnerAbsolute />}
                  <Flex
                    sx={{
                      mb: 0,
                      bg: "white",
                      width: "100%",
                      p: 3,
                      justifyContent: "space-between",
                    }}>
                    <Box>
                      {error && (
                        <Label
                          variant="label.error"
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          Erro ao guardar, tente novamente...
                        </Label>
                      )}
                    </Box>
                    <Box>
                      <Button
                        type="button"
                        variant="outline"
                        mr={2}
                        onClick={() => setRedirect(routes.list)}>
                        <Flex sx={{ alignItems: "center" }}>
                          <Icon name="Close" size={15} color="danger" />
                          <Text ml={2}>Cancelar</Text>
                        </Flex>
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={!isValid || loading}>
                        Guardar
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};
