import React from "react";

import {
  DELETE_CATEGORIES_MUTATION,
  UPDATE_CATEGORIES_MUTATION,
} from "@vipbuques/graphql";
import { CATEGORY_QUERY } from "@vipbuques/graphql";

import { CategoriesDelete } from "./CategoriesDelete";
import { CategoriesForm } from "./CategoriesForm";
import { CATEGORIES_ROUTES } from "./CategoriesRoutes";
import {
  validationSchema,
  deserializeCategories,
  serializeCategories,
} from "./CategoriesUtils";
import { options } from "./CategoriesUtils";
import { BaseView } from "app/baseView";

export const CategoriesView = (props) => {
  const title = "Editar Categoria";
  const id = props.match.params.id;
  const query = CATEGORY_QUERY;
  const updateMutation = UPDATE_CATEGORIES_MUTATION;
  const deleteMutation = DELETE_CATEGORIES_MUTATION;
  const routes = CATEGORIES_ROUTES;
  const queryItem = "categories";
  const deserialize = deserializeCategories;
  const serialize = serializeCategories;
  const updateInput = "updateCategoryInput";
  const deleteInput = "deleteCategoryInput";
  const schema = validationSchema;
  const ItemDelete = CategoriesDelete;
  const ItemForm = CategoriesForm;
  const mutationOptions = options;

  return (
    <BaseView
      id={id}
      title={title}
      query={query}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      routes={routes}
      queryItem={queryItem}
      deserialize={deserialize}
      serialize={serialize}
      updateInput={updateInput}
      deleteInput={deleteInput}
      validationSchema={schema}
      ItemDelete={ItemDelete}
      ItemForm={ItemForm}
      mutationOptions={mutationOptions}
    />
  );
};

export default CategoriesView;
