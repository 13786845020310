"use strict";

exports.__esModule = true;
exports.buttons = void 0;

var _color = require("@theme-ui/color");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const button = {
  cursor: "pointer",
  backgroundColor: "white",
  boxShadow: "rgba(67, 90, 111, 0.14) 0px 0px 0px 1px inset, rgba(67, 90, 111, 0.06) 0px -1px 1px 0px inset",
  borderWidth: "initial",
  borderStyle: "none",
  borderImage: "initial",
  outline: "none",
  borderColor: "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
  ":disabled": {
    color: "disabled",
    bg: "muted",
    cursor: "not-allowed",
    backgroundImage: "none",
    ":hover": {
      backgroundImage: "none"
    }
  },
  ":focus": {
    boxShadow: "rgba(16, 112, 202, 0.14) 0px 0px 0px 3px, rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset, rgba(67, 90, 111, 0.14) 0px -1px 1px 0px inset"
  }
};

const primary = _objectSpread(_objectSpread({}, button), {}, {
  color: "white",
  backgroundImage: t => "linear-gradient(".concat((0, _color.lighten)("black", 0.1)(t), ", ").concat((0, _color.lighten)("black", 0)(t), ")"),
  ":hover": {
    backgroundImage: t => "linear-gradient(".concat((0, _color.lighten)("black", 0.2)(t), ", ").concat((0, _color.lighten)("black", 0)(t), ")")
  }
});

const success = _objectSpread(_objectSpread({}, button), {}, {
  color: "white",
  boxShadow: "rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset, rgba(67, 90, 111, 0.06) 0px -1px 1px 0px inset;",
  backgroundImage: t => "linear-gradient(".concat((0, _color.lighten)("success", 0.1)(t), ", ").concat((0, _color.lighten)("success", 0)(t), ")"),
  ":hover": {
    backgroundImage: t => "linear-gradient(".concat(t.colors.success, ", ").concat(t.colors.success, ")")
  }
});

const warning = _objectSpread(_objectSpread({}, button), {}, {
  color: "white",
  boxShadow: "rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset, rgba(67, 90, 111, 0.06) 0px -1px 1px 0px inset;",
  backgroundImage: t => "linear-gradient(".concat((0, _color.lighten)("warning", 0.1)(t), ", ").concat((0, _color.lighten)("warning", 0)(t), ")"),
  ":hover": {
    backgroundImage: t => "linear-gradient(".concat(t.colors.warning, ", ").concat(t.colors.warning, ")")
  }
});

const danger = _objectSpread(_objectSpread({}, button), {}, {
  color: "white",
  boxShadow: "rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset, rgba(67, 90, 111, 0.06) 0px -1px 1px 0px inset;",
  backgroundImage: t => "linear-gradient(".concat((0, _color.lighten)("danger", 0.1)(t), ", ").concat((0, _color.lighten)("danger", 0)(t), ")"),
  ":hover": {
    backgroundImage: t => "linear-gradient(".concat(t.colors.danger, ", ").concat(t.colors.danger, ")")
  }
});

const small = {
  fontSize: 0,
  py: 1,
  px: 2
};
const large = {
  fontSize: 3,
  px: 4,
  py: 2
};

const outline = _objectSpread(_objectSpread({}, button), {}, {
  color: "text",
  backgroundImage: t => "linear-gradient(".concat(t.colors.white, ", ").concat((0, _color.darken)("white", 0.1)(t), ")"),
  ":hover": {
    backgroundImage: t => "linear-gradient(".concat((0, _color.darken)("white", 0.05)(t), ", ").concat((0, _color.darken)("white", 0.1)(t), ")")
  }
});

const buttons = {
  primary: _objectSpread(_objectSpread({}, button), primary),
  success: _objectSpread({}, success),
  warning: _objectSpread({}, warning),
  danger: _objectSpread({}, danger),
  small: _objectSpread(_objectSpread(_objectSpread({}, primary), small), {}, {
    success: _objectSpread(_objectSpread({}, success), small),
    warning: _objectSpread(_objectSpread({}, warning), small),
    danger: _objectSpread(_objectSpread({}, danger), small)
  }),
  outline: _objectSpread(_objectSpread(_objectSpread({}, primary), outline), {}, {
    success: _objectSpread(_objectSpread({}, outline), {}, {
      color: "success"
    }),
    warning: _objectSpread(_objectSpread({}, outline), {}, {
      color: "warning"
    }),
    danger: _objectSpread(_objectSpread({}, outline), {}, {
      color: "danger"
    })
  }),
  large: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, button), primary), large), {}, {
    success: _objectSpread(_objectSpread({}, success), large),
    warning: _objectSpread(_objectSpread({}, warning), large),
    danger: _objectSpread(_objectSpread({}, danger), large)
  })
};
exports.buttons = buttons;