import React from "react";
import { Box, Flex } from "theme-ui";

import { SwitchField, TextField } from "@vipbuques/fields";

import { RichTextField } from "app/fields";

export const FILE_SUPPORTED_FORMATS = ["application/pdf"];
export const FILE_MAX_SIZE = 1024 * 15;

export const PagesForm = ({ isDisabled = false }) => {
  return (
    <Box>
      <Flex sx={{ mb: 3 }}>
        <Box sx={{ mr: 3 }}>
          <SwitchField
            disabled={isDisabled}
            name="header"
            label="Link no menu"
          />
        </Box>
        <Box sx={{ mr: 3 }}>
          <SwitchField
            disabled={isDisabled}
            name="footer"
            label="Link no rodapé"
          />
        </Box>
      </Flex>
      <TextField label="Título" name="title" required />
      <RichTextField label="Conteúdo" name="content" />
    </Box>
  );
};
