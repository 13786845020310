"use strict";

exports.__esModule = true;
exports.default = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _styledSystem = require("styled-system");

var _themeUi = require("theme-ui");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const Svg = (0, _styled.default)(_themeUi.Box)({
  display: "inline-block",
  flex: "none",
  fill: "currentColor"
}, _styledSystem.size, _styledSystem.color);
Svg.displayName = "Svg";
Svg.defaultProps = {
  as: "svg"
};
var _default = Svg;
exports.default = _default;